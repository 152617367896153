import {gql} from '@apollo/client'

export default {
  M_LOGIN: gql`
    mutation ($login: String!, $pw: String!) {
      loginUser(login: $login, pw: $pw) {
        _id
        nick
        login
        token
        admin
        req
      }
    }
  `,

  Q_NETS: gql`
    query (
      $sort: String
      $up: Boolean
      $rir: String
      $pref: Int
      $cc: String
      $ct: String
      $rgn: String
      $qtyMore: Int
      $qtyLess: Int
      $net: String
      $as: Float
      $ascnt: Int
      $skip: Int
      $free: Boolean
      $status: Status
      $search: String
      $mode: String
      $my: Boolean
    ) {
      nets(
        sortBy: $sort
        up: $up
        rir: $rir
        pref: $pref
        cc: $cc
        ct: $ct
        rgn: $rgn
        qtyMore: $qtyMore
        qtyLess: $qtyLess
        net: $net
        as: $as
        ascnt: $ascnt
        skip: $skip
        free: $free
        status: $status
        search: $search
        mode: $mode
        my: $my
      ) {
        total
        items {
          _id
          net
          up
          pref
          rir
          as
          ascnt
          ases
          cc
          ct
          rgn
          comments {
            dt
            msg
          }
          alloc
        }
      }
    }
  `,

  Q_NET: gql`
    query ($id: String, $net: String) {
      net(_id: $id, net: $net) {
        _id
        net
        up
        rir
        pref
        ups {
          as
          from
          to
        }
        as
        ascnt
        ases
        cc
        ct
        rgn
        geo {
          lt
          lg
          isp
          proxy
          hosting
        }
        more
        less
        whois
        comments {
          dt
          uid
          msg
          user {
            nick
          }
        }
        person
        email
        tel
        price
        status

        alloc
        pa
        lb
        mnt
        desc
        remarks
        domains
        emails

        mod
        transfer {
          _id
          dt
          type
          src_rir
          src_cc
          src_org
          dst_rir
          dst_cc
          dst_org
        }
      }
    }
  `,

  Q_TRANSFERS: gql`
    query Transfers(
      $sort: String
      $type: String
      $src_rir: String
      $src_cc: String
      $src_org: String
      $dst_rir: String
      $dst_cc: String
      $dst_org: String
      $net: String
      $min_pref: Int
      $max_pref: Int
      $ipv4s: Int
      $search: String
      $skip: Int
    ) {
      transfers(
        sortBy: $sort
        type: $type
        src_rir: $src_rir
        src_cc: $src_cc
        src_org: $src_org
        dst_rir: $dst_rir
        dst_cc: $dst_cc
        dst_org: $dst_org
        net: $net
        min_pref: $min_pref
        max_pref: $max_pref
        ipv4s: $ipv4s
        search: $search
        skip: $skip
      ) {
        total
        items {
          _id
          dt
          type

          src_rir
          src_cc
          src_org

          dst_rir
          dst_cc
          dst_org

          min_pref
          max_pref
          ipv4
          ipv4s
        }
      }
    }
  `,

  Q_NET6: gql`
    query Net6($id: String, $net: String) {
      net6(_id: $id, net: $net) {
        _id
        net
        cc
        dt
        p
        t
        whois
        comments {
          dt
          uid
          msg
          user {
            nick
          }
        }
      }
    }
  `,

  Q_NETS6: gql`
    query Nets6($cc: String, $search: String, $p: Int, $t: String, $skip: Int) {
      nets6(cc: $cc, search: $search, p: $p, t: $t, skip: $skip) {
        total
        items {
          _id
          net
          cc
          dt
          p
          t
          comments {
            dt
            msg
          }
        }
      }
    }
  `,

  Q_TRANSFER: gql`
    query Transfer($id: String) {
      transfer(_id: $id) {
        _id
        dt
        type

        src_rir
        src_cc
        src_org

        dst_rir
        dst_cc
        dst_org

        min_pref
        max_pref

        ipv4
        ipv4s
      }
    }
  `,

  Q_ORGS: gql`
    query Orgs($rir: String, $cc: String, $sort: String, $pref: Int, $search: String, $skip: Int) {
      orgs(rir: $rir, cc: $cc, sort: $sort, pref: $pref, search: $search, skip: $skip) {
        total
        items {
          _id
          org
          rir
          cc
          comments {
            dt
            msg
          }
          person
          email
          tel
          transfers_src
          transfers_dst
          min_pref
          max_pref
          total_ips_src
          total_ips_dst
        }
      }
    }
  `,

  Q_ORGS_EMAILS: gql`
    query OrgsEmails($rir: String, $cc: String, $search: String) {
      orgsEmails(rir: $rir, cc: $cc, search: $search)
    }
  `,

  Q_ORG: gql`
    query Org($id: String, $org: String) {
      org(_id: $id, org: $org) {
        _id
        org
        rir
        cc
        addr

        name
        desc
        remarks
        as
        lb
        pa
        mnt
        domains
        emails

        nets
        comments {
          dt
          uid
          msg
          user {
            nick
          }
        }
        person
        email
        tel
        transfers {
          _id
          dt
          type
          src_rir
          src_cc
          src_org
          dst_rir
          dst_cc
          dst_org
          ipv4
        }
        transfers_src
        transfers_dst
        min_pref
        max_pref
        total_ips_src
        total_ips_dst
        upd
      }
    }
  `,

  Q_LEADS: gql`
    query ($sortBy: String) {
      leads(sortBy: $sortBy) {
        total
        items {
          _id
          dt
          my
          nick
          company
          client
          contact
          desc
          type
          rir
          cc
        }
      }
    }
  `,

  M_LEAD_ADD_MOD: gql`
    mutation ($_id: ID, $inp: inLead) {
      leadAddMod(_id: $_id, inp: $inp) {
        _id
        dt
        my
        nick
        company
        client
        contact
        desc
        type
        rir
        cc
      }
    }
  `,

  M_LEAD_REM: gql`
    mutation ($_id: ID) {
      leadRem(_id: $_id)
    }
  `,

  Q_OFFERS: gql`
    query ($sortBy: String) {
      offers(sortBy: $sortBy) {
        total
        items {
          id
          dt
          my
          nick

          rir
          type
          pref
          price
          curr
          escrow
          feeRIR
          feeEscrow
          desc
          direct
          num

          price_step
          price_min
          bids {
            id
            dt
            nick
            bid
            my
          }
          hot
          expire
        }
      }
    }
  `,

  M_OFFER_ADD_MOD: gql`
    mutation ($id: ID, $inp: inOffer) {
      offerAddMod(id: $id, inp: $inp) {
        id
        dt
        my
        nick

        rir
        type
        pref
        price
        curr
        escrow
        feeRIR
        feeEscrow
        desc
        direct
        num

        price_step
        price_min
        bids {
          id
          dt
          nick
          bid
          my
        }
        hot
        expire
      }
    }
  `,

  M_OFFER_REM: gql`
    mutation ($id: ID) {
      offerRem(id: $id)
    }
  `,

  M_OFFER_BID: gql`
    mutation ($id: ID, $bid: Float) {
      offerBid(id: $id, bid: $bid) {
        id
        price_min
        bids {
          id
          dt
          nick
          bid
          my
        }
      }
    }
  `,

  M_OFFER_BID_CANCEL: gql`
    mutation ($id: ID) {
      offerBidCancel(id: $id) {
        id
        price_min
        bids {
          id
          dt
          nick
          bid
          my
        }
      }
    }
  `,

  M_OFFER_RENEW: gql`
    mutation ($id: ID) {
      offerRenew(id: $id) {
        id
        expire
      }
    }
  `,

  M_ORG_UPDTE: gql`
    mutation orgUpdate($id: String!, $person: String, $email: String, $tel: String) {
      orgUpdate(_id: $id, person: $person, email: $email, tel: $tel) {
        _id
      }
    }
  `,

  M_ORG_COMMENT: gql`
    mutation orgComment($id: String!, $msg: String!) {
      orgComment(_id: $id, msg: $msg) {
        _id
        comments {
          dt
          uid
          msg
          user {
            nick
          }
        }
      }
    }
  `,

  M_NET6COMMENT: gql`
    mutation net6Comment($id: String!, $msg: String!) {
      net6Comment(_id: $id, msg: $msg) {
        _id
        comments {
          dt
          uid
          msg
          user {
            nick
          }
        }
      }
    }
  `,

  M_NET: gql`
    mutation ($id: String!, $person: String, $email: String, $tel: String, $price: String, $status: Status) {
      netUpdate(_id: $id, person: $person, email: $email, tel: $tel, price: $price, status: $status) {
        _id
        person
        email
        tel
        price
        status
      }
    }
  `,

  M_NET_COMMENT: gql`
    mutation ($id: String!, $msg: String!) {
      netComment(_id: $id, msg: $msg) {
        _id
        comments {
          dt
          uid
          msg
          user {
            nick
          }
        }
      }
    }
  `,

  M_NET_COMMENT_EDIT: gql`
    mutation ($id: String!, $dt: Int!, $msg: String!) {
      netCommentEdit(_id: $id, dt: $dt, msg: $msg) {
        _id
        comments {
          dt
          uid
          msg
          user {
            nick
          }
        }
      }
    }
  `,

  Q_ME: gql`
    query {
      me {
        _id
        dt
        nick
        login
        admin

        cc
        rir

        tg_pin
      }
    }
  `,

  Q_USERS: gql`
    query {
      users {
        total
        items {
          _id
          dt
          nick
          login
          admin
          req

          cc
          rir
          order
        }
      }
    }
  `,

  Q_USER_STATS: gql`
    query {
      userStats {
        nick
        months {
          mon
          vals {
            k
            v
          }
        }
        days {
          day
          vals {
            k
            v
          }
        }
        hours {
          hour
          vals {
            k
            v
          }
        }
      }
    }
  `,

  M_USER_ADD_MOD: gql`
    mutation ($_id: ID, $inp: inUser!) {
      userAddMod(_id: $_id, inp: $inp) {
        _id
        dt
        nick
        login
        admin
        req
        cc
        rir
        order
      }
    }
  `,

  M_USER_REM: gql`
    mutation ($_id: ID!) {
      userRem(_id: $_id)
    }
  `,

  S_UPDATE: gql`
    subscription {
      update {
        __typename
        ... on Offer {
          id
          dt
          my
          nick

          rir
          type
          pref
          price
          curr
          escrow
          feeRIR
          feeEscrow
          desc
          direct
          num

          price_step
          price_min
          bids {
            id
            dt
            nick
            bid
          }
          hot
          expire
        }
      }
    }
  `,

  Q_REQUESTS: gql`
    query {
      requests {
        items {
          _id
          dt
          my
          nick
          found
          rir
          name
          email
          body
          imap
        }
      }
    }
  `,

  Q_REQUEST_INFO: gql`
    query {
      requestInfo {
        order
        lastCheck
        lastProcess
      }
    }
  `,

  M_REQUEST_ADD: gql`
    mutation ($inp: inRequest!) {
      requestAdd(inp: $inp) {
        _id
        dt
        my
        nick
        found
        rir
        name
        email
        body
        imap
      }
    }
  `,

  Q_COINS: gql`
    query {
      coins {
        id
        chain
        token
      }
    }
  `,

  Q_INVOICES: gql`
    query {
      invoices {
        total
        items {
          id
          dt
          my
          nick
          coin {
            id
            chain
            token
          }
          note
          addr
          amount_usd
          balance
          balance_usd
          filled
        }
      }
    }
  `,

  M_INVOICE: gql`
    mutation ($id: ID, $inp: inInvoice!) {
      invoice(id: $id, inp: $inp) {
        id
        dt
        my
        nick
        coin {
          chain
          token
        }
        note
        addr
        amount_usd
        balance
        balance_usd
        filled
      }
    }
  `,

  M_INVOICE_CHECK_BALANCE: gql`
    mutation ($id: ID) {
      invoiceCheck(id: $id) {
        id
        balance
        balance_usd
        filled
      }
    }
  `,

  Q_WHOIS: gql`
    query ($q: String) {
      whois(q: $q)
    }
  `,

  Q_BL_EVERY: gql`
    query ($my: Boolean) {
      blEvery(my: $my) {
        id
        dt
        net
        found
        done
        eta
        spent
        nick
        my
      }
    }
  `,

  Q_BL_LOAD: gql`
    query ($id: ID) {
      blLoad(id: $id)
    }
  `,

  M_BL_ADD: gql`
    mutation ($net: String) {
      blAdd(net: $net)
    }
  `,

  M_BL_REM: gql`
    mutation ($id: ID) {
      blRem(id: $id)
    }
  `,

  Q_TA_EVERY: gql`
    query {
      tas {
        id
        dt
        nick
        my
        title
        file
        hash
      }
    }
  `,

  M_TA_ADD: gql`
    mutation ($inp: inTA!) {
      taAdd(inp: $inp) {
        id
        dt
        nick
        my
        title
        file
        hash
      }
    }
  `,

  M_TA_REM: gql`
    mutation ($id: ID) {
      taRem(id: $id)
    }
  `,

  Q_RENT_EVERY: gql`
    query {
      rents {
        id
        dt
        my
        nick
        pref
        rir
        type
        price
        curr
        contact
        desc
        expire
      }
    }
  `,

  M_RENT_ADDMOD: gql`
    mutation ($id: ID, $inp: inRent!) {
      rentAddMod(id: $id, inp: $inp) {
        id
        dt
        my
        nick
        pref
        rir
        type
        price
        curr
        contact
        desc
        expire
      }
    }
  `,

  M_RENT_REM: gql`
    mutation ($id: ID) {
      rentRem(id: $id)
    }
  `,


  Q_MEMO: gql`
    query {
      memo
    }
  `,

  M_MEMO_UPD: gql`
    mutation ($memo: String) {
      memoUpd(memo: $memo)
    }
  `
}
