import { h } from 'preact'

const Modal = ({ show = false, title = 'Title', size = 'sm', children, onClose }) => (
  <div class={`modal modal-${size} ${show ? 'active' : ''}`}>
    <a class="modal-overlay" aria-label="Close" onClick={onClose} />
    <div class="modal-container">
      <div class="modal-header">
        <a class="btn btn-clear float-right" aria-label="Close" onClick={onClose} />
        <div class="modal-title h5">{title}</div>
      </div>
      <div class="modal-body">
        <div class="content">{children}</div>
      </div>
    </div>
  </div>
)
export default Modal
