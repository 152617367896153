
		import Async from "../../node_modules/.pnpm/@preact+async-loader@3.0.2_preact@10.19.3/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../node_modules/.pnpm/babel-loader@8.3.0_@babel+core@7.23.2_webpack@4.47.0/node_modules/babel-loader/lib/index.js??ref--4-0!../../node_modules/.pnpm/source-map-loader@1.1.3_webpack@4.47.0/node_modules/source-map-loader/dist/cjs.js!./requests.js");
				typeof cb === 'function' && cb(result);
			}, "route-requests");
		}

		export default Async(load);
	