const timestamp = u => {
  if (!u) return '-'
  const d = new Date(u * 1000)
  if (typeof d !== 'object' || !d.getDate || isNaN(d)) return '-'
  function pad(n) {
    return n < 10 ? `0${n}` : n
  }
  return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}`
}

const date = u => new Date(u * 1000).toLocaleDateString('lt')

const currSign = {
  UAH: '₴',
  USD: '$',
  EUR: '€'
}

const RIR = ['APNIC', 'ARIN', 'RIPE', 'LACNIC', 'AFRINIC']

const dt = tm => {
  const d = new Date(tm * 100000)

  const p = n => (n < 10 ? `0${n}` : n)
  const now = new Date().getTime()
  const was = d.getTime()
  const delta = now - was

  if (delta < 86400000) {
    // within one day
    //const tm = d.getFullYear() + '.' + p(d.getMonth() + 1) + '.' + p(d.getDate())
    return `${Math.round(delta / 3600000)} hr ago`
  }
  if (delta < 2592000000) {
    // within this month
    //item.tm = d.getFullYear() + '.' + p(d.getMonth() + 1) + '.' + p(d.getDate())
    return `${Math.round(delta / 86400000)} days ago`
  }

  // long time ago
  return `${d.getFullYear()}.${p(d.getMonth() + 1)}.${p(d.getDate())}`
}

const loadQ = (keys = [], page, route, path = '/', def = {}) => {
  try {
    const q = JSON.parse(localStorage.getItem(keys.filter(i => !!i).join('-')))
    if (q && q != null && typeof q === 'object' && q !== {}) {
      if (!page && q.skip) route(`${path}/${q.skip / 100 + 1}`)
      return q
    }
  } catch (e) {
    console.log('- loadQ failed keys[', keys, ']: ->', e)
  }
  return def
}

const saveQ = (keys = [], query = {}) => {
  localStorage.setItem(keys.filter(i => !!i).join('-'), JSON.stringify(query))
}

const loadKey = key => {
  try {
    return localStorage.getItem(key)
  } catch (e) {}
}

const saveKey = (key, val) => localStorage.setItem(key, val)

const sel =
  (ref, c, query, setQuery, route, path = null) =>
  () => {
    if (ref.current.value != query[c]) {
      const q = { ...query }
      q[c] = ref.current.value
      if (ref.current.value === '') delete q[c]
      if (q[c] && c === 'up') {
        q[c] = q[c] === '1'
      }
      q.skip = 0
      setQuery(q)
      if (path) route(path)
    }
  }

let inpTimer
const inp =
  (ref, c, query, setQuery, route, path = null) =>
  () => {
    clearTimeout(inpTimer)
    if (ref.current.value != query[c]) {
      inpTimer = setTimeout(() => {
        const q = { ...query }
        q[c] = ref.current.value
        if (q[c] === '') delete q[c]
        if (q[c]) {
          if (c === 'pref') q[c] = +(q[c][0] === '/' ? q[c].substr(1) : q[c])
          if (c === 'ascnt') q[c] = +q[c]
          if (c === 'as') {
            const m = q[c].match(/(\d+)/)
            if (m && m[1]) q[c] = +m[1]
          }
          if (c === 'p' || c === 'min_pref' || c === 'max_pref') q[c] = +q[c].substr(1)
          if (c === 'ipv4s') q[c] = +q[c]
        }
        q.skip = 0
        setQuery(q)
        if (path) route(path)
      }, 500)
    }
  }

const expDays = d => Math.round((d - Date.now()) / 86400 / 1000)

const notify = (title, body, level = 'error') => {
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      new Notification(title, {
        body: body ? `\n${body}` : undefined,
        icon: '/assets/icons/favicon-32x32.png'
      })
    }
  }

  let toast = document.createElement('DIV')
  toast.className = `toast toast-${level} toast-absolute`
  toast.innerHTML = title + (body ? `\n${body}` : '')
  toast.onclick = () => document.body.removeChild(toast)
  document.body.appendChild(toast)

  setTimeout(() => {
    document.body.removeChild(toast)
  }, 2000)
}

module.exports = { timestamp, date, dt, loadQ, saveQ, loadKey, saveKey, sel, inp, currSign, expDays, RIR, notify }
